// Variables
@import 'normalize.css/normalize.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import 'locomotive-scroll/dist/locomotive-scroll.min.css';
@import '~rfs/scss';
@import 'variables';
@import 'global';

#loading {
    width: 100%;
    height: 100vh;
    @include flex-center();
    background: $color-background;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

#header {
    padding: 1.5rem 0;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background: $color-background;
    box-shadow: 0 2px 5px 3px rgba($color: #d0d0d0, $alpha: 0.22);

    #navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar {

            &-logo {
                h1 {
                    @include font-body();
                }
            }

            &-list {
                list-style: none;

                &-item {
                    @include font-small();
                    display: inline-block;
                    margin: 0 2rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-menu {
                cursor: pointer;

                &-line {
                    height: 2px;
                    width: 30px;
                    background: $color-text;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#navbar-mobile {
    background: $color-background;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    transform: scaleX(0);
    transform-origin: left;

    .navbar-mobile {

        &-list {
            margin-top: 7rem;
            list-style: none;

            &-item {
                @include font-small($size: $font-size-body);
                display: block;
                margin: 2rem 0;
            }
        }
    }
}

#intro {
    margin-top: 10rem;
    margin-bottom: 0;

    .intro {

        &-wrap {
            // height: calc(100vh - 70px);
            @include flex-center($direction: column);
        }

        &-title {
            max-width: 60rem;
            text-align: center;
            margin-bottom: 2rem;
        }

        &-img {
            margin-top: 8rem;
        }
    }
}

#quote {
    margin-top: 0;
    background: $color-primary;
    color: $color-background;
    padding: 8rem 0;

    .quote {
        &-wrap {
            max-width: 60rem;
            text-align: center;
            margin: auto;
        }

        &-text {
            @include font-subheading($weight: $font-weight-body);
            line-height: normal;
            font-style: italic;
        }
    }
}

#jobs {

    .jobs {

        &-text {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-bottom {
                font-weight: $font-weight-bold;
                color: $color-primary;
            }
        }

        &-item {
            margin-top: 5rem;

            &-card {
                background: $color-primary;
                color: $color-background;
                padding: 2rem;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-header {
                    margin-bottom: 3rem;
                }

                &-footer {

                    a.link::before,
                    a.link::after {
                        background: $color-background;
                    }

                    a {
                        color: $color-background;
                    }
                }
            }
        }
    }
}


#work {

    .work {

        &-list {
            margin: 5rem 0;

            &-item {
                border-bottom: 1px solid $color-text;
                padding: 2rem 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @extend %fillup;

                &:first-child {
                    border-top: 1px solid $color-text;
                }

                &:hover {
                    .work-list-item-arrow path {
                        fill: $color-background;
                    }

                    .work-list-item-desc {
                        color: $color-background;
                    }
                }

                &-desc {
                    margin-top: 1rem;
                    color: $color-gray;
                }
            }
        }


    }

    .link {
        text-align: center;
        display: table;
        margin: auto;
    }
}

#features {
    .features {
        &-list {

            &-item {
                margin-bottom: 10rem;
            }
        }
    }
}

#demo {
    text-align: center;

    .section-title {
        margin-bottom: 1rem;
    }

    .demo {

        &-desc {
            margin-bottom: 3.5rem;
        }
    }

    img {
        border-radius: 10px;
        box-shadow: 4px 4px 8px 3px rgba($color: #000000, $alpha: 0.05)
    }
}

#contact {
    background: $color-primary;
    color: $color-background;
    padding: 4rem 0;
    overflow: hidden;
    margin-bottom: 4rem;

    .contact {
        &-title {
            margin-bottom: 2rem;
        }

        &-chat {
            margin-top: 8rem;

            &-title {
                @include font-body();
            }

            &-border {
                background: $color-background;
                height: 1px;
                width: 100vw;
                position: relative;
                right: 0;
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }

            &-form {
                margin-top: 4rem;

                input {
                    margin-bottom: 3rem;
                }

                .btn {
                    background: $color-background;
                    color: $color-primary;
                    margin-top: 2rem;
                    float: right;
                }
            }
        }
    }
}

#footer {
    padding-top: 2rem;
    padding-bottom: $margin-section - 10rem;

    .footer {

        &-list {
            list-style: none;
            margin-bottom: 4rem;

            &-item {
                margin-bottom: 0.5rem;
            }
        }
    }
}

@import 'responsive';