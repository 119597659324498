@import './variables';
@import '~rfs/scss';

*,
*:before,
*:after {
    box-sizing: border-box;
}

:root {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    font-size: $font-size-root;
}


html.has-scroll-smooth {
    overflow: hidden;
}

// html[data-direction="down"] #header {
//     transform: translate3d(0, -100%, 0);
// }

body {
    @include font-body();
    font-family: $font-family-sansserif;
    background-color: $color-background;
    color: $color-text;
    overflow-x: hidden;
}

section {
    margin: $margin-section 0;
}

a {
    color: $color-text;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

a.link::before,
a.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: $color-text;
    top: 100%;
    left: 0;
    pointer-events: none;
}

a.link::before {
    content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

a.link:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-heading();
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
small {
    @extend %reset-space;
}

h2 {
    @include font-heading();
}

h3,
h4,
h5,
h6 {
    @include font-subheading();
}

small {
    @include font-small();
}

img {
    max-width: 100%;
}


input, textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    display: block;
    outline: 0;
    border:0;

    &:focus {
        outline: 0;
    }
}

// Global style
.container {
    position: relative;
    max-width: 800px;
}

.title-center {
    display: block;
    margin: auto;
    text-align: center;
}

.btn {
    background: $color-primary;
    color: $color-background;
    padding: 0.8em 1.8em;
    @include font-body($weight: $font-weight-bold);
    border-radius: 10px;
    @extend %box-shadow;
}

.border {
    width: 100%;
    border-bottom: 1px solid $color-text;
}


.masking-text {
    overflow: hidden;
    display: block;

    .line {
        position: relative;
    }
}

.reveal-image {
    position: relative;
    overflow: hidden;
    visibility: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform-origin: bottom;
    }
}

.parallax {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &-image {
        width: 100%;
        height: 110vh;
        object-fit: cover;
    }

    &-overlay {
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0.7;
    }
}

.plane {
    width: 100%;
    height: 50vh;

    img {
        display: none;
    }
}

.text-italic {
    font-style: italic;
}

.img-full {
    width: 100%;
}

.section-title {
    margin-bottom: 5rem;
    color: $color-primary;
}

.link-arrow {
    vertical-align: middle;
    @include font-small($weight: $font-weight-bold);

    .arrow {
        margin-left: 3px;
        transform: scale(1.5);
    }
}